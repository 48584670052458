/* Disable scrollbar */
::-webkit-scrollbar {
  display: none;
}
html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Scroll snap and define the scroll direction */

:root {
  --red: #d56062;
  --blue: #3e7cb1;
  --white: #f7f0f5;
  --gray: #3c3744;
  --brown: #4b4237;
  --black: #2c1a1d;
  /* the safari off-white */
  --off_white: #f9f9f9;
  --true_white: #ffffff;
  margin: 0 auto;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Scroll arrow */

.scroll-arrow {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  z-index: 1000;
  cursor: pointer;
  animation: bounce 2s infinite;
  transition: all 0.35s ease-out;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* App.css */

.App {
  text-align: center;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva,
    Verdana, sans-serif;
}

.snap {
  display: inline-block;
  scroll-snap-align: center;
}

.page {
  display: flex;
  align-items: center;
  height: 100vh;
}

.intro {
  justify-content: center;
  margin-right: 3%;
}

.timeline {
  flex-direction: column;
  background-color: var(--blue);
  overflow: scroll;
  z-index: -2;
}

/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
}

.header__refs {
  display: flex;
  justify-content: space-between;
  align-items: center;
    z-index: 100;
}

.header__title {
  font-size: 2rem;
  color: white;
  background-color: var(--red);
  padding: 0.75rem;
    z-index: 100;
}

.header__img {
  width: 181px;
  object-fit: cover;
    z-index: 100;
}

.header__reflink {
  font-size: 1rem;
  color: var(--blue);
  padding: 0 0.75rem;
  transition: all 0.15s ease-out;
}

.header__reflink:hover {
  color: var(--red);
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

/* Bio.css */

.bio {
  display: flex;
  width: 350px;
}

.bio__title {
  font-size: 1.5rem;
  color: var(--white);
  background-color: var(--red);
}

.bio__content {
  font-size: 1rem;
  color: var(--black);
  text-align: justify;
  font-weight: 300;
    z-index: 100;
}

.bio__content b {
  font-weight: 550;
}

.bio__icon {
  color: var(--red);
  padding-right: 1rem;
    z-index: 100;
}

/* Project Stuff */

.timeline__title {
  position: absolute;
  top: 102.5vh;
  padding: 1rem;
  font-size: 1.5rem;
  color: var(--white);
  background-color: var(--red);
  z-index: 100;
}

.timeline__placeholder {
  /* wastes 15vh height */
  padding-top: 15vh;
  width: 100%;
}

.timeline__card {
  display: inline-block;
  text-align: left;
  z-index: 10;
}

/* Project Card */

.project__card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e4e8;
  min-width: 400px;
    max-width: 600px;
  width: 50%;

  margin: 0 auto;
  padding: 1rem;
  background-color: var(--off_white);
  color: var(--black);
  margin-bottom: 1rem;
}

.project__card__header {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  border-bottom: 1px solid #e1e4e8;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}

.project__card__title {
  color: var(--black);
}

.project__card__date {
  color: var(--gray);
}

.project__card__language {
  color: var(--gray);
  flex-grow: 1;
  text-align: right;
}

.project__card__content {

}

.project__card__tags {
    border-top: 1px solid #e1e4e8;
    margin-top: 1rem;
    padding-top: 0.5rem;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tags__tag {
    margin-right: 0.5rem;
}


.tags__tag:not(:last-child)::after {
    content: ", ";
}

.tags__tag:first-child {
    margin-left: 0.5rem;
}

.releases {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.releases__release {
}

.releases__release:not(:last-child)::after {
    content: ", ";
}

.releases__release:first-child {
    margin-left: 0.5rem;
}


.project__card__description {
}

.bg-wave-1 {
  position: absolute;
  width: 100%;
  bottom: 0vh;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  background-color: var(--off_white);
transition: bottom 0.5s ease-in-out;
  height: 150px;
  z-index: 1;
}

.bg-wave-1 svg {
  width: calc(131% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
}

.bg-wave-1 .shape-fill {
  fill: var(--blue);
}

.footer {
    margin-bottom: 1rem;
    color: var(--white);
}

.footer a {
    color: var(--true_white);
    margin: 0.5rem;
}

.gitfail {
    color: var(--white);
    
    /* center the text */

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 0.25rem;
}

.gitfail a {
    color: var(--true_white);
}

